import 'react-app-polyfill/ie11';
import React from 'react';
import ReactDOM from 'react-dom';
import TagManager from 'react-gtm-module';
import InitialScreen from './containers/InitialScreen/InitialScreen';
import initialize from './utils/initialize';
import environment from './data/environment.json';
import * as serviceWorker from './serviceWorker';
import './styles/index.scss';

const currEnv = initialize(window.location.host, window.location.pathname, environment);

if (currEnv.gtm){
    const tagManagerArgs = {
        gtmId: currEnv.gtm,
    };
    TagManager.initialize(tagManagerArgs);
}

ReactDOM.render(<InitialScreen currEnv={currEnv} />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
