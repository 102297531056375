import React, { Suspense, Component } from 'react';
import RotateScreen from '../../components/RotateScreen/RotateScreen';
import getDevice from '../../utils/detectDevice';

const Routing = React.lazy(() => import('../Routing/Routing.js'));

class InitialScreen extends Component {
    constructor() {
        super();
        this.state = {
            rotateScreen: false,
            device: getDevice.getType(),
        };
    }

    componentDidMount = () => {
        this.root = document.getElementById('root');
        window.addEventListener('resize', this.updateDimensions);
        window.addEventListener('orientationchange', this.setScreenOrientation, false);
        this.updateDimensions();
    };

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
        window.removeEventListener('orientationchange', this.setScreenOrientation);
    }

    setScreenOrientation = () => {
        const { showRotateScreen } = this.state;
        const isLandscape = window.innerWidth > window.innerHeight;
        const isTouch = !this.root.classList.contains('no-touch');
        const device = getDevice.getType();
        // for touch device (no iFrame)
        if (isTouch && device.isDevice) {
            if (isLandscape) {
                // show rotate screen message
                if (showRotateScreen !== true) {
                    this.setState({
                        showRotateScreen: true,
                    });
                }
            } else {
                if (showRotateScreen !== false) {
                    this.setState({
                        showRotateScreen: false,
                    });
                }
            }
        } else {
            // desktop
            if (showRotateScreen !== false) {
                this.setState({ showRotateScreen: false });
            }
        }
    };

    updateDimensions = () => {
        const w = window.innerWidth; //Math.min(document.documentElement.clientWidth, window.innerWidth);
        const h = window.innerHeight; //Math.min(document.documentElement.clientHeight, window.innerHeight);
        const isTouch = getDevice.getType().isDevice;
        if (!isTouch) {
            document.getElementById('root').className = 'no-touch';
        } else {
            document.getElementById('root').className = 'touch';
        }
        let resize = 1;
        if (!isTouch && (w > 820 || h < 1150)) {
            resize = Math.min(w / 820, h / 1150);
            document.getElementById('root').style.transform = `scale(${Number.parseFloat(resize).toFixed(2)})`;
        } else {
            document.getElementById('root').style.transform = `scale(1)`;
        }
        this.setScreenOrientation();
    };

    render() {
        const { currEnv } = this.props;
        const { showRotateScreen } = this.state;
        /* Initial screen should have the styling inline to avoid a request for the css file */
        return (
            <>
                <RotateScreen showRotateScreen={showRotateScreen} />
                <Suspense
                    fallback={
                        <div
                            style={{
                                height: '100%',
                                width: '100%',
                                backgroundColor: 'white',
                            }}
                        >
                            <div
                                style={{
                                    height: '100%',
                                    width: '100%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    color: 'white',
                                    fontWeight: 'bold',
                                    fontSize: '24px',
                                    letterSpacing: '2px',
                                    textTransform: 'uppercase',
                                }}
                            >
                                Loading...
                            </div>
                        </div>
                    }
                >
                    <Routing currEnv={currEnv} />
                </Suspense>
            </>
        );
    }
}

export default InitialScreen;
