const getDevice = {
    getType() {
        // eslint-disable-next-line
        let isiOS =
            navigator.userAgent.match(/(iPad|iPhone|iPod)/g) ;
        // eslint-disable-next-line
        let isDevice =
            navigator.userAgent.match(/(iPad|iPhone|iPod|Android|webOS|BlackBerry|IEMobile|Opera Mini)/g) ||
            navigator.maxTouchPoints > 0 ;
        // eslint-disable-next-line
        let info = {
            orientation: 'landscape',
            ismobileSize: false,
            widthSize: 400,
            platform: 'desktop',
            isiOS: false,
            isDevice: false,
            isBigMobile: false,
        };
        // eslint-disable-next-line
        let resolution = {
            mobileWidth: 767,
            mobileSixthPlusWidth: 413,
        };

        if (isDevice) {
            // FOR MOBILE AND TABLET
            info.isDevice = true;
            if (isiOS) {
                info.isiOS = true;
                // eslint-disable-next-line
                let mql = window.matchMedia('(orientation: portrait)');

                if (mql.matches) {
                    info.orientation = 'portrait';
                    // eslint-disable-next-line
                    info.widthSize = screen.width;
                } else {
                    info.orientation = 'landscape';
                    // eslint-disable-next-line
                    info.widthSize = screen.height;
                }
                // eslint-disable-next-line
                info.ismobileSize = screen.width <= resolution.mobileWidth;
                info.isBigMobile =
                    info.widthSize >= resolution.mobileSixthPlusWidth && info.widthSize <= resolution.mobileWidth;
            } else {
                info.isiOS = false;
                // eslint-disable-next-line
                info.widthSize = screen.width;
                // eslint-disable-next-line
                if (screen.height > screen.width) {
                    info.orientation = 'portrait';
                    // eslint-disable-next-line
                    info.ismobileSize = screen.width <= resolution.mobileWidth;
                    info.isBigMobile =
                        info.widthSize >= resolution.mobileSixthPlusWidth && info.widthSize <= resolution.mobileWidth;
                } else {
                    info.orientation = 'landscape';
                    // eslint-disable-next-line
                    info.ismobileSize = screen.height <= resolution.mobileWidth;
                    info.isBigMobile =
                        info.widthSize >= resolution.mobileSixthPlusWidth && info.widthSize <= resolution.mobileWidth;
                }
            }
            info.platform = info.ismobileSize ? 'mobile' : 'tablet';
        } else {
            // FOR DESKTOP
            info.widthSize = window.innerWidth;
            if (window.innerWidth < window.innerHeight) {
                info.orientation = 'portrait';
                // eslint-disable-next-line
                info.ismobileSize = window.innerWidth <= resolution.mobileWidth;
            } else {
                info.orientation = 'landscape';
                // eslint-disable-next-line
                info.ismobileSize = window.innerHeight <= resolution.mobileWidth;
            }
        }
        return info;
    },
    getBreakpoint() {
        if (window.innerWidth <= '767' || this.getType().platform === 'MOBILE') 
            return 'mobile';
        else if ((window.innerWidth >= '768' && window.innerWidth <= '1023' && (window.innerWidth > window.innerHeight)) || this.getType().platform === 'TABLET')
            return 'mobile'; // tall phones
        else if ((window.innerWidth >= '768' && window.innerWidth <= '1023') || this.getType().platform === 'TABLET')
            return 'tablet';
        return 'desktop';
    },
};
export default getDevice;
