const initialize = (host, path, config) => {
    let currEnv = false;
    Object.keys(config).some((key, i) => {
        if (host === config[key].currentHost && (path.indexOf(config[key].basename) >= 0)) {
            currEnv = key;
        }
        return (host === config[key].currentHost && (path.indexOf(config[key].basename) >= 0));
    });
    // Set to default config environment = production
    let validConfig = config.prod;
    // This is a valid environment
    if (currEnv) {
        validConfig = config[currEnv];
    }
    return validConfig;
}

export default initialize;
