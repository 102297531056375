import React, { memo } from 'react';
import { Animated } from 'react-animated-css';
import './RotateScreen.scss';

const RotateScreen = ({ showRotateScreen }) => {
    if (showRotateScreen) {
        document.body.style.overflowY = 'hidden';
    } else {
        document.body.style.overflowY = 'initial';
    }
    return (
        <Animated
            className={`rotate-container ${showRotateScreen ? 'show' : ''}`}
            animationIn="fadeIn"
            animationOut="fadeOut"
            isVisible={showRotateScreen}
        >
            <div className="rotate-screen">
                <img src={require('../../assets/images/rotate-screen.png')} alt="Rotate" />
            </div>
        </Animated>
    );
};
export default memo(RotateScreen);
